.picture-container .slider1 img {
    height: 34rem;
    margin: auto;
    border-radius: .5rem;
    box-shadow: 
    0 2px 4px rgba(0, 0, 0, 0.5), 
    0 4px 8px rgba(0, 0, 0, 0.5), 
    0 8px 16px rgba(0, 0, 0, 0.5);
}

.picture-container .slider2{
    padding: 0 2% 0 4%;
}

.picture-container .slider2 img {
    width: 50%;
    height: 8rem;
    position: relative;
    border-radius: .5rem;
}

.picture-container .slider2 .overlay:hover {
    cursor: pointer;
}

.image-container{
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.picture-container .slider2 .overlay{
    position: absolute;
    top: 0;
    left: .5rem;
    bottom: 0;
    right: 0;
    padding: 0 .5rem;
    width: calc(100% - 1rem);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: .5rem;
}

.picture-container .slider2 .add-border{
    border-top: .5rem solid #f490a7;
    width: calc(100% - 1rem);
    position: absolute;
    bottom: 0;
}

.picture-button-prev, .picture-button-next {
    color: #f490a7;
    position: absolute;
}

.picture-button-prev:hover, .picture-button-next:hover {
    cursor: pointer;
}

.picture-button-prev{
    top: 50%;
    left: 15%;
}

.picture-button-next{
    top: 50%;
    right: 15%;
}

.picture-heade-text{
    font-family: 'Montserrat Alternates', sans-serif;
}

.line1, .line2 {
    background-color: #b2d4ef;
}

.line1{
    height: 10px;
}

.line2 {
    height: 5px;
}

.frame {
    position: relative;
}

.frame-top ,.frame-bottom, .frame-right, .frame-left {
    width: 60%;
    position: absolute;
}

.frame-top {
    left: 52%;
    transform: translate(-50%);
    top: 2%;
}

.frame-top .line1 {
    width: 87%;
    margin-left: auto;
    margin-right: 3%;
}

.frame-top .line2 {
    width: 100%;
    margin-left: auto;
}

.frame-bottom {
    right: 53%;
    transform: translate(50%);
    bottom: 2%;
}

.frame-bottom .line1{
    width: 88%;
    margin-left: auto;
}

.frame-bottom .line2{
    width: 95%;
    margin-left: auto;
}


.frame-right {
    transform: translate(0, -50%) rotate(90deg);
    right: -7.5%;
    top: 64%;
}

.frame-left {
    transform: translate(0, 50%) rotate(90deg);
    left: -7.5%;
    bottom: -4%;
}

.frame-right .line1 {
    width: 38%;
    margin-left: 2%;
}

.frame-right .line2 {
    width: 60%;
}

.frame-left .line1 {
    width: 25%;
    margin-left: 20%;
}

.frame-left .line2 {
    width: 47%;
}

@media (max-width: 640px) {
    /* .picture-container .slider1 img {
        width: 80%;
    } */

    .picture-button-prev{
        top: 95%;
        left: 5%;
    }

    .picture-button-next{
        top: 95%;
        right: 5%;
    }
    .frame-top ,.frame-bottom, .frame-right, .frame-left {
        width: 95%;
    }

    .frame-right {
        right: -43%;
        top: 45%;
    }

    .frame-left{
        left: -43%;
    }

    .frame-top {
        top: 1%;
    }

     .frame-bottom {
        bottom: 1%;
    }

    .line1{
        height: 5px;
    }

    .line2 {
        height: 3px;
    }
}