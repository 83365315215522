.time {
    font-family: 'Montserrat Alternates', sans-serif;
}

.time{
    font-weight: 300 !important;
}

.time .time-date {
    color: #B2D4EE;
}