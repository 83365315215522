@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Handwritten-Watermark";
    src: local("Handwritten-Watermark"),
    url("./Fonts/Fz-The-Handwritten-Watermark.ttf") format("truetype");
    font-weight: bold;
}

body{
    background-color: #f0f0f0;
}

@media (max-width: 640px) {
    html {
        font-size: 9px;
    }
}

@media (max-width: 576px) {
    html {
        font-size: 8px;
    }
}