.introduction {
    padding: 15% 5% 15%;
    background-color: #F2BAC9;
}

.bride-picture, .groom-picture{
    position: relative;
}

.bride-image {
    width: 78%;
    height: 90%;
    background-position: 50% 44%;;
    transform: rotate(-5deg);
    position: absolute;
    left: 7%;
    top: -5%;
    z-index: 2;
    background-size: cover;
    border: 0.5rem solid white
}

.groom-image {
    width: 78%;
    height: 90%;
    background-position: 50% 58%;
    transform: rotate(3.5deg);
    position: absolute;
    left: 11%;
    top: -5%;
    z-index: 2;
    background-size: cover;
    border: 0.5rem solid white
}

.groom-border {
    width: 80%;
    margin-left: auto;
}

.bride-border {
    width: 80%;
}

.bride-dress {
    position: absolute;
    top: -25%;
    left: 10%;
    z-index: 3;
}

.groom-vest {
    position: absolute;
    bottom: -30%;
    right: 20%;
    z-index: 3;
    width: 28%;
}

.bride-arrow {
    position: absolute;
    top: -40%;
    right: -10%;
    z-index: 3;
}

.arrow-bottom {
    position: absolute;
    left: -35%;
    bottom: -35%;
    z-index: 3;
}

.bride-content, .groom-content {
    color: #ffff;
    font-family: Great Vibes, sans-serif;
}

.bride-content p:nth-child(2), .groom-content p:nth-child(2){
    color: #000 ;
}

.bride-content p:nth-child(3), .groom-content p:nth-child(3){
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 1.1rem;
}

.arrow-ring {
    position: absolute;
    top: -60%;
    right: 50%;
    z-index: 3;
}

@media (max-width: 640px) {

    .introduction {
        padding: 35% 5% 25%;
    }

    .bride-image {
        width: 92%;
        height: 80%;
    }

    .groom-image {
        width: 94%;
        height: 75%;
        left: 7%;
    }
    .bride-arrow {
       display: none;
    }

    .arrow-bottom {
        display: none;
    }

    .bride-dress {
        width: 28%;
    }

    .groom-vest {
        width: 28%;
        bottom: 0;
        right: 10%;
    }

    .bride-content, .groom-content {
        padding: 0 5rem;
    }

    .bride-content p:nth-child(3), .groom-content p:nth-child(3){
        font-size: 1.6rem;
    }

    .bride-picture{
        padding-bottom: 5rem;
    }

    .groom-picture{
        padding-bottom: 7rem;
    }

    .arrow-ring {
        top: -55%;
        right: 50%;
        width: 30%;
    }

    .groom-border {
        width: 100%;
        margin-left: auto;
    }

    .bride-border {
        width: 100%;
    }


}