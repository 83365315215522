.picture-section{
    font-family: 'Great Vibes', sans-serif;
    position: relative;
}


.schedule-container {
    position: relative;
}

.schedule-container hr {
    background-color: #B2D4EF;
    width: 70%;
    height: 5px;
    position: absolute;
    left: 29%;
    top: 45%;
}

/* .schedule-container::before {
    content: '';
    display: block;
    width: 3px;
    height: 105%;
    background-color: #B2D4EF;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    position: absolute;
} */

.schedule-container::after{
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: #B2D4EF;
    position: absolute;
    bottom: 0;
    left: 0%;
    content: '';
    position: absolute;
    transform: rotate(45deg);
}

.schedule-container::before{
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #B2D4EF;
    position: absolute;
    top: 0;
    left: 0%;
}

.schedule-container .line {
    height: 100%;
    width: 5px;
    position: absolute;
    left: 5.5px;
    top: 0;
    background-color: #B2D4EF;
}

.pictute img {
    max-width: 89%;
    margin-left: -1%;
    border-radius: 50%;
    background-color: #f0f0f0;
}

.section1 p {
    display: inline-block;
}

.section1 .top {
    padding-top: 29%;
    color: rgb(60, 60, 60);
}

.section1 .content {
    padding-top: 4%;
    font-size: 1.5rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: rgb(60, 60, 60);
    padding-left: 2rem;
}

.section1 .top p:nth-child(1) {
    color: #f490a7;
}

@media (max-width: 1024px) {
    .section1 .top p:nth-child(2) {
        padding: .5rem;
    }

    .section1 .top p {
        font-size: 2.2rem;
    }
}

@media (max-width: 640px) {
    .pictute img {
        max-width: 85%;
    }
    .section1 .top p {
        font-size: 2.75rem;
    }

    .section1 .top p:nth-child(2) {
        padding: 0 1.5rem;
    }

    .section1 .content {
        font-size: 1.4rem;
    }

    .schedule-container hr {
        top: 10.5rem;
    }

    .schedule-container .special-line {
        top: 11rem;
    }

    .schedule-container .line {
        left: .2rem;
    }

}