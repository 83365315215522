.prewedding-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.prewedding-content{
    width: 50%;
}

.prewedding-text{
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 500;
    font-style: italic;
}

.prewedding-video-container{
    border-radius: 0.5rem;
    overflow: hidden;
}

.prewedding-video-container iframe{
    width: 100%;
    height: 34rem;
}

@media (max-width: 640px) {
    .prewedding-content{
        width: 95%;
    }
}