.openning{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #ffff;
    font-family: 'Montserrat Alternates', sans-serif;
}

.openning-text{
}

.openning .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.openning .transition {
background: linear-gradient(180deg, rgba(240, 240, 240, 0) 0%, rgba(240, 240, 240, 0.151042) 20.83%, #F2F2F2 100%);
}

.fullName, .shortName {
    position: relative;
    z-index: 2;
}

.openning .fullName {
    font-family: 'Handwritten-Watermark', sans-serif;
    font-size: 12rem;
}

.openning .date {
    font-family: 'Great Vibes', cursive;
}

.openning .fullName .and {
    font-family: 'Handwritten-Watermark', sans-serif;
    font-size: 4.5rem;
}

/* @media (max-width: 640px) {
    .openning .fullName {
        font-size: 6rem;
    }

    .shortName {
        font-size: 2.5rem;
    }
} */