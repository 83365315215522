.thanks {
    background-color: #AEC6CF;
}

.thanks-title {
    font-family: 'Handwritten-Watermark', sans-serif;
}

.thanks-content {
    font-family: sans-serif;
    letter-spacing: 1px;
    font-style: italic;
}

.thanks .content {
    font-size: 1.1rem;
}
